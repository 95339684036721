import { Injectable } from "@angular/core";
import {
  CATEGORY_TYPES,
  FirebaseDbService,
  GlobalsService,
  MONTHS,
  RequestService,
  RequestsAction,
  Statuses,
  subscriptionInfo,
} from "@app/core";
import { AccountsService } from "../accounts/accounts.service";

@Injectable({
  providedIn: "root",
})
export class AideRequestsService {
  formProgress!: boolean;
  loading!: boolean;
  submissionSuccessful = false;
  categoriesCount: any = CATEGORY_TYPES;
  chartData: any;
  features: any;

  constructor(
    private firedb: FirebaseDbService,
    private globals: GlobalsService,
    private acountService: AccountsService,
    private api: RequestService
  ) {}

  private readonly actions: Record<RequestsAction, Function> = {
    getRequestInfo: async (requestid: string) => {
      const [requestInfo, features] = await Promise.all([
        await this.getRequests(requestid),
        await this.getRequestsFeatures(),
      ]);
      this.features = features;
      return requestInfo;
    },
    getOffers: async (businessid: string) => {
      const requests: any = await this.getOffers(businessid);
      this.categoriesCount = this.sortByCategory(requests);
      return requests;
    },
    getRequests: async (filter: any) => {
      return await this.getAllRequests(filter);
    },
    onMessage: async (requestid: string, data: any) => {
      return await this.firedb.push_data(
        `requests/${requestid}/conversations`,
        data
      );
    },
    postOffers: async (data: any, requestid: string) => {
      const resp = await this.postOffer(data, requestid);
      this.submissionSuccessful = true;
      return resp;
    },
    updateStatus: async (data: any, selectedRequest: any) => {
      await this.updateRequestsStatus(data, selectedRequest);
      return await this.getRequests(selectedRequest["requestid"]);
    },
  };

  public async execute(action: RequestsAction, payload?: any, option?: any) {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await this.actions[action](payload, option);
        resolve(resp);
      } catch (e: any) {
        this.formProgress = false;
        this.loading = false;
        reject(e);
      }
    });
  }

  private async getRequests(requestid: string) {
    const { message } = (await this.api.get(`requests/${requestid}`)) as {
      message: Array<{ [index: string]: any }>;
    };
    return message;
  }

  private async getOffers(businessid: string) {
    const { message } = (await this.api.get(
      `requests/my_offers?businessid=${businessid}`
    )) as {
      message: Array<{ [index: string]: any }>;
    };
    return message;
  }

  private async getAllRequests(filter: any) {
    let url: string = `requests/all?limit=${filter.limit}`;
    if (filter.subcategory) url += `&subcategory=${filter.subcategory}`;
    if (filter.status) url += `&status=${filter.status}`;

    const { message } = (await this.api.get(url)) as {
      message: Array<{ [index: string]: any }>;
    };
    return message;
  }

  private async getRequestsFeatures() {
    const { message } = (await this.api.get("requests/features")) as {
      message: Array<any>;
    };
    return message;
  }

  /// returun total count of request by cattegory
  public sortByCategory(requests: any) {
    this.resetCategoryCount();
    return CATEGORY_TYPES.map((category: { name: string; count: number }) => {
      requests.forEach((request: { [index: string]: any }) => {
        if (request["category"] === category.name) category.count += 1;
      });
      return category;
    });
  }

  public resetCategoryCount() {
    this.categoriesCount = CATEGORY_TYPES.map((category) => {
      category.count = 0;
      return category;
    });
  }

  private async updateRequestsStatus(data: any, selectedRequest: any) {
    this.formProgress = true;

    // Strigify the value of some keys in requests object.
    ["expenseSnapshot", "subcategory", "accepted_offer", "files"].forEach(
      (key: string) => {
        if (key in selectedRequest)
          selectedRequest[key] = JSON.stringify(selectedRequest[key]);
      }
    );
    selectedRequest["status"] = data["status"];
    await this.api.update(
      `requests/${selectedRequest["requestid"]}`,
      selectedRequest
    );

    //Update the request preference
    data["date_added"] = new Date().toDateString();
    await this.api.post("requests/preference", {
      requestid: selectedRequest["requestid"],
      sources: JSON.stringify([data]),
      type: "notes",
    });

    this.formProgress = false;
    this.submissionSuccessful = true;
    this.globals.toast.success("Update successfully");
  }

  private async postOffer(offer: any, requestid: string) {
    this.formProgress = true;
    const resp = await this.api.post("requests/offers", {
      uid: this.acountService.account?.["uid"],
      country: JSON.stringify(this.globals.config.country),
      requestid,
      businessid: this.globals.config.business.businessid,
      offers: JSON.stringify(offer),
    });
    this.formProgress = false;
    this.globals.toast.success("Offer created successfully");
    return resp;
  }

  public generateCategoryRequestChart(requests: any) {
    const sortedRequests = requests.sort((a: any, b: any) => {
      const dateA: any = new Date(a.date_updated);
      const dateB: any = new Date(b.date_updated);
      return dateA - dateB;
    });

    const chartData: any = [];
    const groupByMonths = sortedRequests.reduce((acc: any, obj: any) => {
      const figureDate = obj.date_updated.slice(0, 7);
      const monthIndex = new Date(figureDate).getMonth();
      const yr = String(new Date(figureDate).getFullYear());
      const month = MONTHS[monthIndex];
      const expressionDate = `${month} ${yr}`;

      if (!acc[expressionDate]) acc[expressionDate] = [];
      acc[expressionDate].push(obj);
      return acc;
    }, {});
    let chartLabels: any = Object.keys(groupByMonths);
    chartLabels = chartLabels.slice(
      chartLabels.length - 20,
      chartLabels.length
    );

    chartLabels.forEach((value: any, key: any) => {
      subscriptionInfo.products.forEach((obj: any, j: any) => {
        if (!chartData[j]) {
          chartData[j] = {
            name: obj.name,
            data: new Array(chartLabels.length).fill(0),
          };
        }
        chartData[j].data[key] = 0;

        groupByMonths[value].forEach((data: any) => {
          if (
            data.subcategory_name.toLowerCase() === obj.name.toLowerCase() &&
            data.category.toLowerCase() === obj.category.toLowerCase()
          ) {
            chartData[j].data[key]++;
          }
        });
      });
    });

    return {
      data: chartData,
      labels: chartLabels,
    };
  }

  public async getRequestCategories() {
    const resp = await this.api.get(`requests/categories`);
    return resp;
  }
}
