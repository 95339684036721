import { Injectable, inject } from "@angular/core";
import {
  RequestService,
  GlobalsService,
  GlobalErrorHandlerService,
  IMediaFile,
  BusinessActions,
} from "../../../core";

@Injectable({
  providedIn: "root",
})
export class BusinessService {
  private api = inject(RequestService);
  protected globalsService = inject(GlobalsService);
  private error = inject(GlobalErrorHandlerService);

  userBusiness!: { [index: string]: any };
  businesses: Array<any> | undefined;
  subscription: any;
  selectedStaff: any;
  selectedBusiness: any;
  staffs: { [index: string]: any }[] = [];
  formProgress = false;
  loading!: boolean;
  fileLoading!: boolean;
  imgLoading!: boolean;
  savePreferenceProgress!: boolean;
  requiredFile!: string;
  imageURL!: string;
  documentInfo!: IMediaFile;

  private readonly actions: Record<BusinessActions, Function> = {
    "create-business": async (payload: any) => {
      await this.createBusiness(payload);
    },

    "get-business": async (payload: any) => {
      const businessid: string = payload.sources
        ? payload.sources.businessid
        : payload.businessid;
      return await this.getBusiness(businessid);
    },

    "edit-business": async (payload: any) => {
      await this.editBusiness(payload);
      this.globalsService.toast.success("Edited successfully");
    },

    "delete-business": async (payload: any) => {
      await this.deleteBusiness(payload);
    },

    "get-document-info": async (payload: any) => {
      await this.getDocumentInfo(payload);
    },

    "save-doc-to-preference": async (payload: any) => {
      await this.saveRequiredDocsToPreference(payload);
      this.globalsService.toast.success("Saved successfully");
    },

    "create-staff": async (payload: any) => {
      await this.createStaff(payload);
      this.globalsService.toast.success("Account created successsfully");
    },
    "edit-staff": async (payload: any) => {
      await this.editStaff(payload);
      this.globalsService.toast.success("Account edited successsfully");
    },

    "delete-staff": async (payload: any) => {
      await this.deleteStaff(payload);
    },

    "get-all-businesses": async () => {
      // await this.getAllBusinesses();
    },

    "get-subscription": async () => {
      await this.getBusinessSubscription();
    },

    "get-staffs": async () => {
      await this.getStaffs();
    },

    "get-img-url": async (payload: any) => {
      await this.getImageURL(payload);
    },
  };

  public async execute(action: BusinessActions, payload?: any) {
    try {
      return await this.actions[action](payload);
    } catch (e: any) {
      this.formProgress = false;
      this.savePreferenceProgress = false;
      this.fileLoading = false;
      this.imgLoading = false;
      this.error.handleError(e);
    }
  }

  /************************************ */
  /** Get Business */
  private async getBusiness(businessid: string = "") {
    this.loading = true;
    const resp = (await this.api.get("businesses/" + businessid)) as {
      message: Array<any>;
    };

    this.businesses = resp.message;
    if (businessid != "")
      this.userBusiness = this.businesses.filter(
        (business: any) => business.businessid === businessid
      )[0];

    this.globalsService.config.business = this.userBusiness;
    this.globalsService.storage.saveItem("config", this.globalsService.config);
    this.loading = false;
    return this.businesses;
  }

  /********************************* */
  /** Create Business */
  private async createBusiness(data: any) {
    this.formProgress = true;
    const resp = (await this.api.post(`businesses`, data)) as {
      message: string;
    };

    this.formProgress = false;
  }

  /********************************* */
  /** Edit Business */
  private async editBusiness(data: any) {
    this.formProgress = true;

    data["uid"] = this.userBusiness?.["uid"];
    data["businessid"] = this.userBusiness?.["businessid"];

    await this.api.update(`businesses/${data["businessid"]}`, data);
    await this.getBusiness();

    this.formProgress = false;
  }

  /********************************* */
  /** Delete Business */
  private async deleteBusiness(data: any) {
    const resp = (await this.api.delete(
      `businesses/${data.businessid}`
    )) as any;

    return resp.message;
  }

  /** If user registers as an agent the purpose of this function is
   * to also use the agent information on the form to fill the business
   * information and return the data.
   */
  public getAutoFilledBusinessInfo(data: any) {
    const businessInfo = {
      uid: "",
      name: "",
      phone: "",
      email: "",
      business_idnumber: "",
      abbrev: "",
      summary: "",
      address: "",
      business_license: "",
      business_industry: "",
      business_type: "",
      country: "",
      website: "",
      logo: "",
    };

    const firstName = data.firstname;
    const lastName = data.lastname;
    const businessName = `${firstName} ${lastName}`;

    businessInfo.name = businessName;
    businessInfo.email = data.email;
    businessInfo.country = data.country;

    return businessInfo;
  }

  /********************************* */
  /** Get Document Info */
  private async getDocumentInfo(data: File) {
    this.fileLoading = true;
    const mediaFile: any = await this.globalsService.uploadFile(data);
    this.documentInfo = mediaFile;
    this.fileLoading = false;
  }

  /********************************* */
  /** Get Image URL */
  private async getImageURL(data: File) {
    this.imgLoading = true;

    const mediaFile: any = await this.globalsService.uploadFile(data);
    this.imageURL = mediaFile.url;

    this.imgLoading = false;
  }

  /********************************* */
  /** Save Docs To Preference */
  public async saveRequiredDocsToPreference(documents: any) {
    this.savePreferenceProgress = true;
    const data = {
      businessid: this.userBusiness?.["businessid"],
      type: "document_required",
      sources: JSON.stringify(documents),
    };

    await this.api.post("businesses/preference", data);

    this.savePreferenceProgress = false;
  }

  /********************************* */
  /** Create Staff */
  private async createStaff(data: any) {
    this.formProgress = true;
    data["access"] = "business_staff_partner";
    data["businessid"] = this.userBusiness?.["businessid"];
    data["password"] = "Logarithms@1";

    await this.api.post("businesses/account", data);
    await this.getStaffs();

    this.imageURL = "";

    this.formProgress = false;
  }

  /********************************* */
  /** Edit Staff */
  private async editStaff(data: any) {
    this.formProgress = true;

    data["uid"] = this.selectedStaff["uid"];
    data["access"] = this.selectedStaff["access"];

    await this.api.update(`users/${data["uid"]}`, data);
    await this.getStaffs();

    this.formProgress = false;
  }

  /********************************* */
  /** Delete Staff */
  private async deleteStaff(data: any) {
    await this.api.delete(`user/${data["uid"]}`, data);
    await this.getStaffs();
  }

  /********************************* */
  /** Get Staffs */
  private async getStaffs() {
    this.loading = true;
    await this.getBusiness();

    const accounts = this.userBusiness?.["accounts"] as Array<{
      [index: string]: any;
    }>;

    this.staffs = accounts.filter(
      (account: { [index: string]: any }) =>
        account["access"] === "business_staff_partner"
    );

    // let staffs = accounts.filter(
    //   (account: { [index: string]: any }) =>
    //     account['access'] === 'business_staff_partner'
    // );

    // // Filter out staffs with id
    // staffs = staffs.filter((staff: { [index: string]: any }) =>
    //   staff.hasOwnProperty('id')
    // );

    // // Remove duplicate from staffs
    // let nonDuplicate: { [index: string]: any }[] = [];
    // staffs.forEach((staff: { [index: string]: any }) => {
    //   let isPresent = this.staffPresent(nonDuplicate, staff);
    //   if (!isPresent) nonDuplicate.push(staff);
    // });

    // this.staffs = nonDuplicate;
    this.loading = false;
  }

  private readonly staffPresent = (
    filteredStaffs: Array<{ [index: string]: any }>,
    staff: { [index: string]: any }
  ) => {
    if (filteredStaffs.length) {
      return filteredStaffs.some(
        (filteredStaff: { [index: string]: any }) =>
          filteredStaff["id"] == staff["id"]
      );
    }
    return false;
  };

  /********************************* */
  /** Get All Businesses */
  private async getBusinessSubscription() {
    const resp: any = await this.api.get("subscriptions");
    this.subscription =
      resp.message.filter(
        (sub: any) => sub.info.type === "business-subscription"
      ) || [];
  }
}
