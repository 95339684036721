import { Injectable, inject } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { AccountsService } from "@app/shared/services";
import { Observable } from "rxjs";
import { GlobalsService } from "../services";

@Injectable({
  providedIn: "root",
})
export class AuthGuardsService implements CanActivate {
  constructor(
    private router: Router,
    private globals: GlobalsService,
    private accountsService: AccountsService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(async (resolve, reject) => {
      try {
        await this.accountsService.isLoggedOn();
        resolve(true);
      } catch (ex) {
        this.router.navigateByUrl("/auth/login");
        reject(false);
      }
    });
  }
}
