import { ErrorHandler, Injectable, Injector, inject } from "@angular/core";
import { ActivationStart, Router } from "@angular/router";
import { StorageServices } from "./storage.service";
import { ToasterService } from "./toaster.service";
import { IConfig } from "../interfaces";
import { cloudinary, subscriptionInfo } from "../utils";
import { RequestListingComponent } from "@app/modules/requests/pages/request-listing/request-listing.component";
import { RequestService } from "./request";
const LOGTAG = "[GlobalErrorHandlerService]";

@Injectable({
  providedIn: "root",
})
export class GlobalsService {
  public spinner: any;
  public router: Router;
  public storage: StorageServices;
  public toast: ToasterService;
  private api: RequestService;
  // public api: RequestService = inject(RequestService);

  routerAdded: boolean = false;
  pageData: any;
  showsplash: boolean = true;
  loading: boolean = false;
  title: string = "overview";
  breadcrumb: string | undefined = undefined;
  config: IConfig = {
    login: false,
    jwt: null,
    refresh_token: null,
    country: null,
    current_country: null,
    country_code: null,
    business: null,
  };

  constructor() {
    this.router = inject(Router);
    this.storage = inject(StorageServices);
    this.toast = inject(ToasterService);
    this.api = inject(RequestService);
  }

  navigate(path: string, subpage: boolean = true) {
    this.router.navigate([path], { replaceUrl: subpage });
  }

  async toastAlert(message: string, mode: string) {
    this.toast.custom(message, mode);
  }

  setUrlTitle() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof ActivationStart) {
        this.title = event.snapshot.data["title"];
        this.pageData = {
          ...event.snapshot.params,
          title: this.title,
        };
      }
    });
  }

  async initializeApp() {
    try {
      this.showsplash = true;
      this.setUrlTitle();
      setTimeout(() => {
        this.showsplash = false;
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  }

  async logout() {
    // await this.api.delete('logout');
    this.storage.clear();
    this.config.login = false;
    this.navigate("auth/login", false);
  }

  /** Upload file */
  public async uploadFile(data: any) {
    const formData: any = new FormData();
    formData.append("file", data);
    formData.append("upload_preset", cloudinary.upload_preset);
    formData.append("skipAuthorization", "true");

    return await this.api.upload(``, formData);
  }
}

@Injectable({
  providedIn: "root",
})
export class GlobalErrorHandlerService implements ErrorHandler {
  // private toasterService = inject(ToasterService);
  constructor(private injector: Injector) {}

  public async handleError(error: unknown) {
    try {
      // const globalService: GlobalsService =
      //   this.injector.get<GlobalsService>(GlobalsService);
      // const message = this.getMessageFromUnknownError(error);
      // console.log(message);
      // await globalService.toastAlert(message, 'error');
    } catch (errorHandlerError) {
      console.error(`${LOGTAG} Internal exception:`, errorHandlerError);
    }
  }

  private getMessageFromUnknownError(error: unknown): string {
    let message = "An unknown error has occurred.";
    if (error instanceof Object && "rejection" in error) {
      error = (error as any).rejection;
    }
    if (error instanceof Error && error.message) {
      message = error.message;
    }
    return message;
  }
}
